<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-email-template', params: { accountId: this.$route.params.accountId } }">Email Templates</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ emailTemplate.label }}</h1>
                <!-- <p class="text-caption text-center">Email contact overview</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 mx-5" v-if="emailTemplate">
                <v-col cols="12">
                    <p class="text-end text-caption mb-0 grey--text">Email Template {{ emailTemplate.id }}</p>
                    <v-card>
                        <v-toolbar color="teal darken-2" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>Email Template</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon color="white" @click="sendTestEmail" v-bind="attrs" v-on="on">
                                    <font-awesome-icon :icon="['fas', 'paper-plane']"/>
                                    <!-- Send test email -->
                                </v-btn>
                            </template>
                            <span>Send test email</span>
                        </v-tooltip>
                        <!-- <v-btn icon>
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>

                        <v-btn icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->

                        </v-toolbar>
                        <!-- <v-card-text> -->
                            <v-simple-table>
                                <template #default>
                                    <tbody>
                                        <!-- NOTE: currently we only allow setting one brand profile for the entire account; possibly in the future we might allow a per-template override -->
                                        <!-- <tr>
                                            <th class="text-right">BrandProfile</th>
                                            <td>
                                                <span v-if="brandProfile">{{ brandProfile.alias }}</span> < ! - - TODO: make this a TextLink to view the brandprofile at cdn.brandprofile.org in a new window, OR to our account - view - brandprofile page in this window - - >
                                                <v-btn icon color="teal darken-2" @click="editEmailTemplateBrandProfile">
                                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                                </v-btn>

                                            </td>
                                        </tr> -->
                                        <tr>
                                            <th class="text-right">From</th>
                                            <td>
                        <span v-if="emailOrigin">{{ emailOrigin.label }} &lt;{{ emailOrigin.mailbox }}@{{ emailOrigin.domain }}&gt;</span>
                        <v-btn icon color="teal darken-2" @click="editEmailTemplateOrigin">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">
                        <v-btn icon color="teal darken-2" @click="customizeReplyto = !customizeReplyto">
                            <font-awesome-icon :icon="['far', 'check-square']" v-if="customizeReplyto"/>
                            <font-awesome-icon :icon="['far', 'square']" v-if="!customizeReplyto"/>
                        </v-btn>
                                                Reply-To
                                            </th>
                                            <td>
                                                <v-span v-if="customizeReplyto">
                        <span v-if="emailReplyto">{{ emailReplyto.label }} &lt;{{ emailReplyto.mailbox }}@{{ emailReplyto.domain }}&gt;</span>
                        <v-btn icon color="teal darken-2" @click="editEmailTemplateReplyto">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                                                </v-span>
                                                <v-span v-if="!customizeReplyto">
                                                    Same as "from" address.
                                                </v-span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Subject</th>
                                            <td>
                        {{ emailTemplate.subject }}
                        <v-btn icon color="teal darken-2" @click="editEmailTemplateSubject">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Postal Address</th>
                                            <td>
                        <span v-if="postalAddress">
                            <span class="font-weight-bold">{{ postalAddress.label }}</span>
                            <span v-if="postalAddressDisplay">, {{ postalAddressDisplay }}</span>
                        </span>
                        <v-btn icon color="teal darken-2" @click="editEmailTemplatePostalAddress">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Opt-in topics</th>
                                            <td>
                                                <span v-if="emailTemplate.topic_list.length === 0">
                                                    Select at least one topic.
                                                    <!-- TODO: tooltip? "Only contacts who are opted in to at least one of the topics will receive dispatches of this email template."" -->
                                                </span>
                                                <v-chip small class="ma-1" v-for="topic_id in emailTemplate.topic_list" :key="topic_id">
                                                    {{ topicLabelMap[topic_id] }}
                                                </v-chip>
                                                <v-btn icon color="teal darken-2" @click="editEmailTemplateTopicList">
                                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        <!-- </v-card-text> -->

                        <!-- <v-toolbar dense flat>
                            <v-toolbar-title>Content</v-toolbar-title>
                        </v-toolbar> -->
                        <v-divider/>

                        <v-toolbar dense flat>
                            <v-tabs v-model="tab">
                            <v-tabs-slider color="teal darken-2"></v-tabs-slider>

                            <v-tab>HTML</v-tab>
                            <v-tab>Text</v-tab>
                            </v-tabs>
                            <v-spacer/>
                            <template v-if="editEmailTemplateContent">
                                <v-btn text color="teal darken-2" @click="saveEditEmailTemplateHtml" :disabled="!isChangedTemplateHtml" v-if="tab === 0">
                                    <font-awesome-icon :icon="['fas', 'check']"/> <!-- or:  far file-check -->
                                    Save
                                </v-btn>
                                <v-btn text color="teal darken-2" @click="saveEditEmailTemplateText" :disabled="!isChangedTemplateText" v-if="tab === 1">
                                    <font-awesome-icon :icon="['fas', 'check']"/> <!-- or:  far file-check -->
                                    Save
                                </v-btn>
                            </template>
                        <v-btn icon color="teal darken-2" @click="editEmailTemplateContent = !editEmailTemplateContent">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="!editEmailTemplateContent"/>
                            <font-awesome-icon :icon="['fas', 'eye']" v-if="editEmailTemplateContent"/>
                        </v-btn>

                        </v-toolbar>
                        <v-divider/>

                        <v-tabs-items v-model="tab">
                            <!-- HTML -->
                        <v-tab-item class="px-5">
                            <template v-if="editEmailTemplateContent">
                        <v-textarea v-model="editableEmailTemplateHtml" label="HTML content">
                        </v-textarea>

                            </template>
                            <template v-if="!editEmailTemplateContent">
                                <div v-html="emailTemplatePreviewHtml"></div>
                            </template>

                            <!-- <v-card flat>
                            <v-card-text v-text="text"></v-card-text>
                            </v-card> -->
                        </v-tab-item>
                        <!-- Text -->
                        <v-tab-item class="px-5">
                            <template v-if="editEmailTemplateContent">
                        <v-textarea v-model="editableEmailTemplateText" label="Text content">
                        </v-textarea>

                            </template>
                            <template v-if="!editEmailTemplateContent">
                                <div v-html="emailTemplatePreviewText"></div>
                            </template>
                        </v-tab-item>
                        </v-tabs-items>
                        <!-- <v-divider/>
                        <v-row no-gutters class="px-2 py-2">
                            <v-col>
                                <v-btn color="green white--text" @click="sendTestEmail">
                                    <font-awesome-icon :icon="['fas', 'paper-plane']"/>
                                    Send test email
                                </v-btn>
                            </v-col>
                        </v-row> -->
                    </v-card>
                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, emailTemplateId: this.$route.params.emailTemplateId } }">Access control</router-link>
                    </p> -->

                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Email Template Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.emailTemplate, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editEmailTemplateBrandProfileDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Set the BrandProfile to use with this template</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEmailTemplateBrandProfile">
                    <v-row>
                    <v-col>
                        <p>
                            The BrandProfile contains the "from" address, "reply-to" address, and postal address to use with this template.
                        </p>
                        <v-select outlined dense :items="editableBrandProfileChoices" v-model="editableBrandProfileId" color="teal darken-2" label="BrandProfile" required>
                            <!-- <template #append-outer>
                                <v-btn icon color="teal darken-2" @click="onClickAddBrandProfile">
                                    <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template> -->
                        </v-select>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEmailTemplateBrandProfile" :disabled="!isEditEmailTemplateBrandProfileComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateBrandProfileDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplateOriginDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email "from" address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEmailTemplateOrigin">
                    <v-row>
                    <v-col>
                        <p>
                            The email "from" address is the first thing users will see.
                            You can change it at any time.
                        </p>
                        <p>
                            The email address you specify here will also be used as a reply-to
                            address unless you check the box for using a different reply-to address.
                        </p>
                        <p>
                            The domain you use in this "from" address must be the same one
                            that was set up for anti-spam tools.
                        </p>
                        <!-- TODO: show list of pre-configured "from" addresses to choose from, and a button to add a new one -->
                        <!-- <v-text-field v-model="editableEmailOriginId" label="From address"></v-text-field> -->

                        <v-select outlined dense :items="editableEmailOriginChoices" v-model="editableEmailOriginId" color="teal darken-2" label="From address" required>
                            <!-- <template #append-outer>
                                <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                    <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template> -->
                        </v-select>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEmailTemplateOrigin" :disabled="!isEditEmailTemplateOriginComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateOriginDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplateReplytoDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email "reply-to" address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEmailTemplateReplyto">
                    <v-row>
                    <v-col>
                        <p>
                            The email "reply-to" address is where you will receive replies to your emails.
                            You can change it at any time to affect future dispatches of this template.
                        </p>
                        <p>
                            You should monitor this address for replies asking for help or to unsubscribe.
                        </p>
                        <!-- TODO: show list of pre-configured "from" addresses to choose from, and a button to add a new one -->
                        <!-- <v-text-field v-model="editableEmailOriginId" label="From address"></v-text-field> -->

                        <v-select outlined dense :items="editableEmailReplytoChoices" v-model="editableEmailReplytoId" color="teal darken-2" label="Reply-to address" required>
                            <!-- <template #append-outer>
                                <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                    <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template> -->
                        </v-select>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEmailTemplateReplyto" :disabled="!isEditEmailTemplateReplytoComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateReplytoDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplateSubjectDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email subject</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEditEmailTemplateSubject">
                    <v-row>
                    <v-col>
                        <p>
                            The email subject is the first thing users will see.
                            You can change it at any time.
                        </p>
                        <v-text-field v-model="editableEmailTemplateSubject" label="Email subject"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailTemplateSubject" :disabled="!isEditEmailTemplateSubjectComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateSubjectDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplatePostalAddressDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the postal address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEmailTemplatePostalAddress">
                    <v-row>
                    <v-col>
                        <p>
                            The postal address appears in the footer of the message.
                            It is required by law.
                            <!-- add citations for US, EU, etc. (e.g. the 'CAN-SPAM Act' in the United States). or maybe link to our blog where we'll put all the info -->
                            You can change it at any time.
                        </p>
                        <v-select outlined dense :items="editablePostalAddressChoices" v-model="editablePostalAddressId" color="teal darken-2" label="Postal address" required>
                            <!-- <template #append-outer>
                                <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                    <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template> -->
                        </v-select>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEmailTemplatePostalAddress" :disabled="!isEditEmailTemplatePostalAddressComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplatePostalAddressDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplateTextDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email body text</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEditEmailTemplateText">
                    <v-row>
                    <v-col>
                        <p>
                            This is the plain text content of the email.
                        </p>
                        <!-- <v-text-field v-model="editableEmailTemplateText" label="Email text"></v-text-field> -->
                        <v-textarea v-model="editableEmailTemplateText" label="Text content">
                        </v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailTemplateText" :disabled="!isEditEmailTemplateTextComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateTextDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplateHtmlDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email body HTML</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEditEmailTemplateHtml">
                    <v-row>
                    <v-col>
                        <p>
                            This is the HTML content of the email.
                        </p>
                        <!-- <v-text-field v-model="editableEmailTemplateText" label="Email text"></v-text-field> -->
                        <v-textarea v-model="editableEmailTemplateHtml" label="HTML content">
                        </v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailTemplateHtml" :disabled="!isEditEmailTemplateHtmlComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateHtmlDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailTemplateTopicListDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the topic list</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2" @submit.prevent="saveEditEmailTemplateHtml">
                    <v-row>
                    <v-col>
                        <p>
                            The topic list determines which email contacts will receive dispatches of this email template.
                        </p>
                        <p>
                            A contact must have opted-in to at least one of the selected topics in order to receive the dispatch.
                        </p>
                        <v-checkbox v-for="topic in topicList" :key="topic.id" :label="topic.label" :value="topic.id" v-model="editableTopicList">
                        </v-checkbox>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailTemplateTopicList">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailTemplateTopicListDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-btn:not(.v-btn--icon) .v-btn__content svg {
    margin-right: 8px;
}
/* .v-btn .v-btn__content svg + span {
    margin-left: 8px;
} */
/* table {
    table-layout: auto;
} */
table tr th {
    min-width: 125px;
}
table tr td {
    width: 100%;
}
</style>

<script>
import { mapState } from 'vuex';
// import EmailTemplateList from '@/components/account-dashboard/EmailTemplateList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // EmailTemplateList,
    },
    data: () => ({
        account: null,
        emailTemplate: null,
        brandProfileList: [],
        emailOriginList: [],
        emailReplytoList: [],
        topicList: [], // each item is { id, label }
        topicLabelMap: {}, // { id => label }
        editableBrandProfileChoices: [],
        editableBrandProfileId: null, // the selected brandprofile id
        editableEmailOriginChoices: [],
        editableEmailOriginId: null, // the selected email origin id
        editableEmailReplytoChoices: [],
        editableEmailReplytoId: null, // the selected email reply-to id
        editEmailTemplateBrandProfileDialog: false,
        editEmailTemplateOriginDialog: false,
        editEmailTemplateReplytoDialog: false,
        editEmailTemplateSubjectDialog: false,
        editableEmailTemplateSubject: null,
        editEmailTemplateTextDialog: false,
        editableEmailTemplateText: null,
        editEmailTemplateHtmlDialog: false,
        editableEmailTemplateHtml: null,
        editEmailTemplateTopicListDialog: false,
        editableTopicList: [], // each item is "topic_id"
        tab: null,
        customizeReplyto: null,
        postalAddressList: [],
        editablePostalAddressChoices: [],
        editablePostalAddressId: null,
        editEmailTemplatePostalAddressDialog: false,
        editEmailTemplateContent: false, // true in edit mode, false in preview mode
        mostRecentSavedTemplateTextContent: 1,
        mostRecentSavedTemplateHtmlContent: 1,
        error: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.emailTemplate !== null;
        },
        // isPublished() {
        //     return this.form?.invite_id;
        // },
        // statusDisplay() {
        //     if (this.isPublished) {
        //         return 'Published';
        //     }
        //     return 'Draft';
        // },
        // allocated() {
        //     if (typeof this.form?.allocated_gb === 'number') {
        //         return `${this.emailTemplate.allocated_gb} GB`;
        //     }
        //     return 'Unknown';
        // },
        // usage() {
        //     if (typeof this.form?.usage === 'number') {
        //         return humanReadableByteCountSI(this.emailTemplate.usage);
        //     }
        //     return 'Unknown';
        // },
        isChangedTemplateHtml() {
            // console.log('isChangedTemplateHtml called');
            // console.log(`isChangedTemplateHtml editableEmailTemplateHtml: ${this.editableEmailTemplateHtml.length}`);
            // console.log(`isChangedTemplateHtml emailTemplate.html_content: ${this.emailTemplate.html_content.length}`);
            return this.mostRecentSavedTemplateHtmlContent && this.editableEmailTemplateHtml !== this.emailTemplate.html_content;
        },
        isChangedTemplateText() {
            // console.log('isChangedTemplateText called');
            // console.log(`isChangedTemplateText editableEmailTemplateText: ${this.editableEmailTemplateText.length}`);
            // console.log(`isChangedTemplateText emailTemplate.text_content: ${this.emailTemplate.text_content.length}`);
            return this.mostRecentSavedTemplateTextContent && this.editableEmailTemplateText !== this.emailTemplate.text_content;
        },
        isEditEmailTemplateBrandProfileComplete() {
            return typeof this.editableBrandProfileId === 'string' && this.editableBrandProfileId.trim().length > 0;
        },
        isEditEmailTemplateOriginComplete() {
            return typeof this.editableEmailOriginId === 'string' && this.editableEmailOriginId.trim().length > 0;
        },
        isEditEmailTemplateReplytoComplete() {
            return typeof this.editableEmailReplytoId === 'string' && this.editableEmailReplytoId.trim().length > 0;
        },
        isEditEmailTemplateSubjectComplete() {
            return typeof this.editableEmailTemplateSubject === 'string' && this.editableEmailTemplateSubject.trim().length > 0;
        },
        isEditEmailTemplatePostalAddressComplete() {
            return typeof this.editablePostalAddressId === 'string' && this.editablePostalAddressId.trim().length > 0;
        },
        isEditEmailTemplateTextComplete() {
            return typeof this.editableEmailTemplateText === 'string' && this.editableEmailTemplateText.trim().length > 0;
        },
        isEditEmailTemplateHtmlComplete() {
            return typeof this.editableEmailTemplateHtml === 'string' && this.editableEmailTemplateHtml.trim().length > 0;
        },
        isNewItemEmailTemplateComplete() {
            if (['hr', 'break'].includes(this.editableItemType)) {
                return true;
            }
            return typeof this.editableInputLabel === 'string'
            && this.editableInputLabel.trim().length > 0;
            // && this.editableInputType !== null
            // && ['select', 'text', 'email', 'url', 'uri', 'numeric', 'currency', 'date', 'year', 'month', 'weekday', 'time', 'color', 'file', 'image'].includes(this.editableInputType)
            // && typeof this.editableInputKey === 'string'
            // && this.editableInputKey.trim().length > 0;
        },
        isEditItemEmailTemplateComplete() {
            return true; // TODO: essential/required fields + item type specific fields
        },
        isEditEmailTemplateConfirmationComplete() {
            return typeof this.editableEmailTemplateConfirmationHeadline === 'string'
            && this.editableEmailTemplateConfirmationHeadline.trim().length > 0;
            // The message is currently optional.
            // && this.editableEmailTemplateConfirmationMessage !== null
            // && this.editableEmailTemplateConfirmationMessage.trim().length > 0;
        },
        brandProfile() {
            return this.brandProfileList.find((item) => item.id === this.editableBrandProfileId);
        },
        emailOrigin() {
            return this.emailOriginList.find((item) => item.id === this.editableEmailOriginId);
        },
        emailReplyto() {
            return this.emailReplytoList.find((item) => item.id === this.editableEmailReplytoId);
        },
        emailTemplatePreviewText() {
            const paragraphs = this.emailTemplate?.text_content?.split('\n') ?? [];
            return paragraphs.map((item) => `<p>${item}</p>`).join('');
        },
        emailTemplatePreviewHtml() {
            return this.emailTemplate.html_content;
        },
        postalAddress() {
            return this.postalAddressList.find((item) => item.id === this.editablePostalAddressId);
        },
        postalAddressDisplay() {
            if (this.postalAddress === null) {
                return '';
            }
            // everything except the label
            const parts = [
                this.postalAddress.line1,
                this.postalAddress.line2,
                this.postalAddress.line3,
                this.postalAddress.line4,
                this.postalAddress.line5,
                this.postalAddress.city,
                this.postalAddress.state,
                this.postalAddress.code,
                this.postalAddress.country,
            ];
            return parts.filter((part) => typeof part === 'string' && part.trim().length > 0).join(', ');
        },
    },
    watch: {
        /*
        editableInputLabel(newValue) {
            // only set the input key automatically the first time; after that, it should stay the same so user doesn't end up with many columns representing the same input just because they changed the label
            // if (typeof this.editableInputKey !== 'string' || this.editableInputKey.length === 0) {
            //     this.editableInputKey = compact(newValue);
            // }
        },
        */
        editableInputSelectListEditCustomValue(newValue, oldValue) {
            if (oldValue && !newValue) {
                // property changed from 'custom value' to 'not custom value' so set the value equal to the label
                this.editableInputSelectListEditValue = this.editableInputSelectListEditLabel;
            }
        },
        editableInputSelectListEditLabel(newValue) {
            if (!this.editableInputSelectListEditCustomValue) {
                // choice value is not custom, so change it to equal the label
                this.editableInputSelectListEditValue = newValue;
            }
        },
        customizeReplyto(newValue, oldValue) {
            // when user enables "customize reply-to" the UI shows it for editing and we don't need to do anything here.
            // when the user disables "customize reply-to" we need to set email_replyto_id to null and save it.
            // when the email template first loads, `customizeReplyto` is set based on the value of email_replyto_id,
            // so we don't take any action if the change is from null to a boolean; we only take action if the previous
            // value was a boolean
            if (typeof oldValue === 'boolean' && !newValue) {
                this.editableEmailReplytoId = null;
                this.saveEmailTemplateReplyto();
            }
        },
        editEmailTemplateContent(newValue) {
            if (newValue) {
                // edit mode
            } else {
                // preview mode
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadEmailTemplate() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailTemplate: true });
                const response = await this.$client.account(this.$route.params.accountId).emailTemplate.get(this.$route.params.emailTemplateId);
                console.log(`loadEmailTemplate: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailTemplate = response;
                    this.editableEmailOriginId = response.email_origin_id;
                    this.editableEmailReplytoId = response.email_replyto_id;
                    if (response.email_replyto_id) {
                        this.customizeReplyto = true;
                    } else {
                        this.customizeReplyto = false;
                    }
                    this.editableEmailTemplateSubject = response.subject ?? '';
                    this.editablePostalAddressId = response.postal_address_id;
                    this.editableEmailTemplateText = response.text_content ?? '';
                    this.editableEmailTemplateHtml = response.html_content ?? '';
                    this.editableTopicList = response.topic_list ?? [];
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email template');
                }
            } catch (err) {
                console.error('failed to load email template', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailTemplate: false });
            }
        },
        async loadBrandProfileList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrandProfileList: true });
                const response = await this.$client.account(this.$route.params.accountId).brandProfile.search();
                console.log(`loadBrandProfileList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.brandProfileList = response.list;
                    // TODO: maybe editableEmailOriginChoices should be a computed property???
                    this.editableBrandProfileChoices = response.list.map((item) => ({
                        text: item.alias, // `<${item.mailbox}@${item.domain}>`,
                        value: item.id,
                    }));
                } else {
                    console.error('failed to load brandprofile list');
                    this.brandProfileList = [];
                    this.editableBrandProfileChoices = [];
                }
            } catch (err) {
                console.error('failed to load brandprofile list', err);
                // this.$set(this.form, 'invite_id', null);
            } finally {
                this.$store.commit('loading', { loadBrandProfileList: false });
            }
        },
        async loadEmailOriginList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailOriginList: true });
                const response = await this.$client.account(this.$route.params.accountId).emailOrigin.search();
                console.log(`loadEmailOriginList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.emailOriginList = response.list;
                    // TODO: maybe editableEmailOriginChoices should be a computed property???
                    this.editableEmailOriginChoices = response.list.map((item) => ({
                        text: `${item.label} <${item.mailbox}@${item.domain}>`,
                        value: item.id,
                    }));
                } else {
                    console.error('failed to load brandprofile list');
                    this.emailOriginList = [];
                    this.editableEmailOriginChoices = [];
                }
            } catch (err) {
                console.error('failed to load brandprofile list', err);
                // this.$set(this.form, 'invite_id', null);
            } finally {
                this.$store.commit('loading', { loadEmailOriginList: false });
            }
        },
        async loadEmailReplytoList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailReplytoList: true });
                const response = await this.$client.account(this.$route.params.accountId).emailReplyto.search();
                console.log(`loadEmailReplytoList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.emailReplytoList = response.list;
                    // TODO: maybe editableEmailReplytoChoices should be a computed property???
                    this.editableEmailReplytoChoices = response.list.map((item) => ({
                        text: `${item.label} <${item.mailbox}@${item.domain}>`,
                        value: item.id,
                    }));
                } else {
                    console.error('failed to load email reply-to list');
                    this.emailReplytoList = [];
                    this.editableEmailReplytoChoices = [];
                }
            } catch (err) {
                console.error('failed to load email reply-to list', err);
                // this.$set(this.form, 'invite_id', null);
            } finally {
                this.$store.commit('loading', { loadEmailReplytoList: false });
            }
        },
        async loadTopicList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadTopicList: true });
                const response = await this.$client.account(this.$route.params.accountId).topic.search();
                console.log(`loadTopicList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.topicList = response.list;
                    const labelMap = {};
                    response.list.forEach((item) => {
                        labelMap[item.id] = item.label;
                    });
                    this.topicLabelMap = labelMap;
                } else {
                    console.error('failed to load topic list');
                    this.topicList = [];
                    this.topicLabelMap = {};
                }
            } catch (err) {
                console.error('failed to load topic list', err);
                // this.$set(this.form, 'invite_id', null);
            } finally {
                this.$store.commit('loading', { loadTopicList: false });
            }
        },
        async loadPostalAddressList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadPostalAddressList: true });
                const response = await this.$client.account(this.$route.params.accountId).postalAddress.search();
                console.log(`loadPostalAddressList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.postalAddressList = response.list;
                    // TODO: maybe editableEmailOriginChoices should be a computed property???
                    const postalAddressDisplay = (item) => {
                        const parts = [
                            item.label,
                            item.line1,
                            item.line2,
                            item.line3,
                            item.line4,
                            item.line5,
                            item.city,
                            item.state,
                            item.code,
                            item.country,
                        ];
                        return parts.filter((part) => typeof part === 'string' && part.trim().length > 0).join(', ');
                    };
                    this.editablePostalAddressChoices = response.list.map((item) => ({
                        text: `${postalAddressDisplay(item)}`,
                        value: item.id,
                    }));
                } else {
                    console.error('failed to load postal address list');
                    this.postalAddressList = [];
                    this.editablePostalAddressChoices = [];
                }
            } catch (err) {
                console.error('failed to load postal address list', err);
                // this.$set(this.form, 'invite_id', null);
            } finally {
                this.$store.commit('loading', { loadPostalAddressList: false });
            }
        },
        async save(itemAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditEmailTemplate: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).emailTemplate.edit({ id: this.$route.params.emailTemplateId }, itemAttr);
                console.log(`saveEditEmailTemplate: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email template' });
                return false;
            } catch (err) {
                console.error('failed to edit email template', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email template' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditEmailTemplate: false });
            }
        },
        editEmailTemplateBrandProfile() {
            this.editableBrandProfileId = this.emailTemplate.brandprofile_id;
            this.editEmailTemplateBrandProfileDialog = true;
        },
        editEmailTemplateOrigin() {
            this.editableEmailOriginId = this.emailTemplate.email_origin_id;
            this.editEmailTemplateOriginDialog = true;
        },
        editEmailTemplateReplyto() {
            this.editableEmailReplytoId = this.emailTemplate.email_replyto_id;
            this.editEmailTemplateReplytoDialog = true;
        },
        editEmailTemplateSubject() {
            this.editableEmailTemplateSubject = this.emailTemplate.subject;
            this.editEmailTemplateSubjectDialog = true;
        },
        editEmailTemplatePostalAddress() {
            this.editablePostalAddressId = this.emailTemplate.postal_address_id;
            this.editEmailTemplatePostalAddressDialog = true;
        },
        // editEmailTemplateText() {
        //     this.editableEmailTemplateText = this.emailTemplate.text;
        //     this.editEmailTemplateTextDialog = true;
        // },
        // editEmailTemplateHtml() {
        //     this.editableEmailTemplateHtml = this.emailTemplate.html;
        //     this.editEmailTemplateHtmlDialog = true;
        // },
        editEmailTemplateTopicList() {
            this.editableTopicList = this.emailTemplate.topic_list;
            this.editEmailTemplateTopicListDialog = true;
        },
        async saveEmailTemplateBrandProfile() {
            const isEdited = await this.save({ brandprofile_id: this.editableBrandProfileId });
            if (isEdited) {
                this.editEmailTemplateBrandProfileDialog = false;
                this.$set(this.emailTemplate, 'brandprofile_id', this.editableBrandProfileId);
                // TODO: based on the selected profile, we can also automatically set email origin, replyto, and postal address
            }
        },
        async saveEmailTemplateOrigin() {
            const isEdited = await this.save({ email_origin_id: this.editableEmailOriginId });
            if (isEdited) {
                this.editEmailTemplateOriginDialog = false;
                this.$set(this.emailTemplate, 'email_origin_id', this.editableEmailOriginId);
            }
        },
        async saveEmailTemplateReplyto() {
            const isEdited = await this.save({ email_replyto_id: this.editableEmailReplytoId });
            if (isEdited) {
                this.editEmailTemplateReplytoDialog = false;
                this.$set(this.emailTemplate, 'email_replyto_id', this.editableEmailReplytoId);
            }
        },
        async saveEditEmailTemplateSubject() {
            const isEdited = await this.save({ subject: this.editableEmailTemplateSubject });
            if (isEdited) {
                this.editEmailTemplateSubjectDialog = false;
                this.$set(this.emailTemplate, 'subject', this.editableEmailTemplateSubject);
            }
        },
        async saveEmailTemplatePostalAddress() {
            const isEdited = await this.save({ postal_address_id: this.editablePostalAddressId });
            if (isEdited) {
                this.editEmailTemplatePostalAddressDialog = false;
                this.$set(this.emailTemplate, 'postal_address_id', this.editablePostalAddressId);
            }
        },
        // async saveEmailTemplateContent() {
        //     console.log(`saveEmailTemplateContent before: isChangedTemplateText ${this.isChangedTemplateText} isChangedTemplateHtml ${this.isChangedTemplateHtml}`);
        //     if (this.isChangedTemplateHtml) {
        //         this.saveEditEmailTemplateText();
        //     }
        //     if (this.isChangedTemplateText) {
        //         this.saveEditEmailTemplateHtml();
        //     }
        // },
        async saveEditEmailTemplateText() {
            const isEdited = await this.save({ text_content: this.editableEmailTemplateText });
            // this.editEmailTemplateTextDialog = false; // TODO: remove, not using the dialog anymore
            if (isEdited) {
                this.$set(this.emailTemplate, 'text_content', this.editableEmailTemplateText); // this is not really working.
                this.mostRecentSavedTemplateTextContent = Date.now(); // force recompute of isEditedTemplateHtml and isEditedTemplateText, which for some reason doesn't get upated when we call $set above
            }
        },
        async saveEditEmailTemplateHtml() {
            const isEdited = await this.save({ html_content: this.editableEmailTemplateHtml });
            // this.editEmailTemplateHtmlDialog = false; // TODO: remove, not using the dialog anymore
            if (isEdited) {
                this.$set(this.emailTemplate, 'html_content', this.editableEmailTemplateHtml); // this is not really working.
                this.mostRecentSavedTemplateHtmlContent = Date.now(); // force recompute of isEditedTemplateHtml and isEditedTemplateHtml, which for some reason doesn't get upated when we call $set above
            }
        },
        async saveEditEmailTemplateTopicList() {
            const isEdited = await this.save({ topic_list: this.editableTopicList });
            if (isEdited) {
                this.editEmailTemplateTopicListDialog = false;
                this.$set(this.emailTemplate, 'topic_list', this.editableTopicList);
            }
        },
        async sendTestEmail() {
            try {
                // if there are any unsaved changes to the template, save them now
                // TODO: if we need to save both, save them concurrently before we continue
                if (this.isChangedTemplateHtml) {
                    await this.saveEditEmailTemplateHtml();
                }
                if (this.isChangedTemplateText) {
                    await this.saveEditEmailTemplateText();
                }
                this.error = false;
                this.$store.commit('loading', { sendTestEmail: true });
                // send a test email to the current user
                // TODO: if there's any data required in the email for placeholders, current user record might not have it... so we need to
                // extract placeholders from email, and let user fill in whatever values they want for them, before they send the test... or
                // let user select sample data from a set of pre-defined sample data records for testing
                const data = {}; // TODO: sample data to use in the test email (everything except recipient email address, which will be current user)
                const response = await this.$client.account(this.$route.params.accountId).emailTemplate.check(this.$route.params.emailTemplateId, { item: 'test', data });
                console.log(`sendTestEmail: response ${JSON.stringify(response)}`);
                if (response?.sent) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to send test email' });
                return false;
            } catch (err) {
                console.error('failed to send test email', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to send test email' });
                return false;
            } finally {
                this.$store.commit('loading', { sendTestEmail: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadEmailTemplate();
        this.loadBrandProfileList();
        this.loadEmailOriginList();
        this.loadEmailReplytoList();
        this.loadPostalAddressList();
        this.loadTopicList();
    },
};
</script>
